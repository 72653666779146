
@import 'variables';
// Import old platform theme styles.
@import 'checkout/style';

// Override the accounts styles that are overriding/altering platform styles.
body.page-platform {
  background: #fff;
  font-family: @font-family-sans-serif;
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: @font-family-sans-serif;
  }
}
body.admin-menu.navbar-is-fixed-top.page-platform,
body.admin-menu.navbar-is-fixed-top.page-checkout {
  padding-top: 0 !important;
}
