// Checkout pages.\
.not-logged-in.page-checkout,
.page-checkout-login-register {
  h1.page-header {
    display: none;
  }
}
body.not-logged-in.page-user,
body.not-logged-in.page-error-denied,
body.not-logged-in.page-error-not-found,
body.page-platform-login,
body.page-checkout.login_register {
  h1.page-header {
    font-size: 30px;
    margin-bottom: 40px;
    color: @gray-dark;
    display: block;
  }
  .divider {
    text-align: center;
    border-bottom: 2px solid #eaeaea;
    height: 11px;
    width: 720px;
    max-width: 95%;
    margin: 0 auto 50px;
    .text {
      display: inline-block;
      background-color: #fff;
      margin-bottom: -16px;
      padding: 0 30px;
      color: #a8a9a9;
      text-transform: uppercase;
    }
  }
  .block-openid-connect {
    margin-bottom: 30px;
  }
  .switch-form {
    color: #5d5e5e;
    text-align: center;
    border-top: 2px solid #eaeaea;
    width: 720px;
    max-width: 95%;
    padding-top: 30px;
    margin: 10px auto 40px;
    font-size: 14px;
  }
}
body.not-logged-in.page-platform-login h1.page-header {
  margin-top: 0;
  margin-bottom: 40px;
}
#block-commerce-checkout-progress-indication {
  position: relative;
  .progress-bar {
    background: darken(@brand-primary, 3%);
    height: 2px;
    float: right;
    position: absolute;
    top: 12px;
    right: 75px;
    width: 490px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  ul {
    padding: 30px 0 0;
    margin: 0;
    position: relative;
  }
  li {
    list-style: none;
    color: darken(@brand-primary, 10%);
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 10px 20px;
    position: relative;
    padding-left: 30px;
    &.active {
      color: #fff;
    }
    &:before {
      content: " ";
      background: darken(@brand-primary, 3%);
      height: 20px;
      width: 20px;
      border-radius: 20px;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
    }
    &.active:before {
      background: #fff;
    }
    &.visited:before {
      content: "\f00c";
      font-family: "FontAwesome";
      color: @brand-primary;
      line-height: 20px;
      background: #fff;
    }
  }
}

// Region
.platform-area {
  padding-top: 30px;
  display: block;
  margin: 0 auto 50px;
  .form-radios {
    text-align: center;
  }
  .form-item {
    margin: 0;
  }
  .form-type-radio {
    text-align: center;
    vertical-align: top;
    margin-top: 0 !important;
    display: inline-block;
    margin: 0 10px 50px;
    label {
      border: 1px solid #b4b5b4;
      padding: 10px 0;
      height: 160px;
      width: 160px;
    }
    .cluster-type {
      font-size: 12px;
    }
    .cluster-map {
      font-size: 85px;
      color: #b4b5b4;
      padding: 0;
    }
    .cluster-label {
      font-size: 14px;
      min-height: 40px;
    }
    &:hover,
    &.active {
      label {
        border-color: @brand-primary;
      }
      label .blimp-platform-label:before,
      .cluster-map {
        color: @brand-primary;
      }
      .entity-blimp-platform {
        border: 1px solid @brand-primary;
      }
    }
    &.active label:after {
      border: 1px solid @brand-primary;
      content: "\f00c";
      font-family: "FontAwesome";
      color: @brand-primary;
      line-height: 17px;
      text-align: center;
      height: 18px;
      width: 18px;
      font-size: 12px;
    }
    .form-radio {
      position: absolute;
      left: -9999px;
    }
  }
  label.control-label {
    margin: 0 auto;
    position: relative;
    padding: 10px;
    .blimp-platform-label {
      padding-top: 80px;
      position: relative;
    }
    &:after {
      content: " ";
      position: absolute;
      left: 50%;
      margin-left: -8px;
      height: 18px;
      width: 18px;
      background: transparent;
      border: 1px solid #b4b5b4;
      border-radius: 20px;
      bottom: 20px;
    }
  }
  .field-name-platform-logo,
  .blimp-platform-label {
    display: block;
    text-align: center;
  }
  .blimp-platform-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 40px;
  }
}
.platform-zone {
  width: 250px;
  margin: 0 auto 30px;
}
#commerce-checkout-form-register {
  .platform-area .form-type-radios {
    display: inline-block;
    width: 100%;
  }
  .form-type-select {
    max-width: 300px;
    margin: 0 auto 30px;
  }
}
.page-checkout .checkout-buttons {
  clear: both;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
}
// Billing page
#commerce-checkout-form-checkout {
  > div > .messages.error {
    background: #FCE9E9 url("@{image-path}/icon-error-circle.png") no-repeat scroll 10px 35px;
    padding: 40px 0 40px 60px;
    color: @gray-dark;
    font-size: 16px;
    margin: 30px 0;
  }
  fieldset.panel {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    legend {
      border: none;
      background: transparent;
    }
  }
  fieldset.billing-information {
    margin-bottom: 30px;
    .form-group:last-child {
      margin-bottom: 15px;
    }
  }
  div.step3 {
    position: relative;
  }
  span.step {
    color: #959595;
    position: absolute;
    top: 7px;
    left: 20px;
    z-index: 2;
  }
  .container {
    position: relative;
    width: 100%;
  }
  #edit-customer-profile-billing .messages {
    margin-bottom: 20px;
  }
  .customer_profile_billing,
  .blimp_support_terms,
  .cart_contents {
    position: relative;
    .fieldset-wrapper {
      padding: 0 20px;
    }
    label {
      text-transform: none;
    }
    .panel-title {
      font-size: 14px;
    }
    #edit-customer-profile-billing-edit-button {
      background: transparent;
      border: 0;
      color: #0099fa;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 0;
      left: 175px;
      font-size: 12px;
      text-transform: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  #customer-profile-billing-ajax-wrapper #customer-profile-billing-ajax-wrapper {
    width: 100%;
  }
  .customer_profile_billing {
    padding: 0 0 0 20px;
    margin-bottom: 20px;
    legend {
      padding: 0;
      margin: 0;
      border-bottom: none;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      height: 40px;
      color: #666;
      .pane-title {
        font-size: 14px;
      }
    }
    .fieldset-wrapper {
      padding: 0;
      .field-name-commerce-customer-address {
        font-size: 16px;
      }
      .form-item {
        margin: 10px 10px 10px 0;
        width: 100%;
        float: none;
        > input {
          min-width: inherit;
          max-width: 100%;
          width: 100%;
          font-size: 16px;
          padding-left: 10px;
          height: 36px;
          line-height: 36px;
          float: left;
        }
      }
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-first-name {
      float: left;
      width: 30%;
      margin-bottom: 0;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-last-name {
      margin-left: 34%;
      margin-bottom: 0;
      width: 66%;
      float: none;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area {
      width: 64%;
      float: left;
      margin-right: 3%;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-locality {
      width: 100%;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code {
      width: 33%;
      float: right;
      margin-right: 0;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-country,
    .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area {
      position: relative;
      label {
        font: 0/0 none;
        color: transparent;
        display: block;
        margin: 0;
      }
      .chosen-container {
        width: 100% !important;
        span {
          font-size: 16px;
          padding-left: 2px;
        }
      }
      .ajax-progress {
        position: absolute;
        top: 5px;
        right: 30px;
      }
    }
    #addressfield-wrapper > .form-group {
      margin: 0;
    }
    .street-block,
    .locality-block {
      .form-item {
        //@include clearfix;
      }
    }
  }

  fieldset {
    border-bottom: 0;
    legend {
      border-bottom: 1px solid #e7e7e7;
      height: 40px;
      padding: 0 0 0 80px;
      margin-bottom: 30px;
    //@include box-sizing;
    }
  }
  .customer_profile_eu_vat_rc {
    padding-left: 20px;
    padding-top: 0;
    > .panel-body {
      padding: 0;
    }
    .form-type-textfield {
      position: relative;
      input {
        padding-right: 40px;
        width: 58%;
      }
      .ajax-progress {
        position: absolute;
        top: 5px;
        right: 30px;
      }
    }
  }
  .active fieldset legend span {
    //color: $turquoise;
  }
  .cart_contents table {
    margin-bottom: 0;
    th {
      color: #a1a1a1;
      border-bottom: 0;
      text-transform: none;
    }
    tr.odd,
    tr.even {
      border-bottom: 0;
    }
    tr.component-type-estimation-total {
      border-bottom: 1px solid #ccc;
    }
    td {
      color: #898989;
      font-size: 14px;
      .field-label {
        float: left;
      }
    }
    td.price,
    th.views-field-commerce-total {
      width: 120px;
    }
    td.views-field-line-item-title {
      padding-left: 30px;
    }
    td.price,
    td.views-field-line-item-title {
      color: #252525;
    }
  }
  .confirmation-payment {
    clear: left;
    fieldset.cart_contents {
      padding-bottom: 50px;
    }
    fieldset.commerce_payment {
      padding: 0;
      .fieldset-wrapper {
        padding: 0;
      }
      .commerce-credit-card-expiration {
        select {
          width: 100px;
          min-width: 100px;
        }
      }
      .stripe-code {
        width: 100px;
        min-width: 100px;
      }
    }
    .commerce-order-handler-area-order-total {
      td {
        font-weight: 600;
        color: #464646;
        padding: 20px 5px;
      }
      td.component-title {
        padding-left: 30px;
      }
      td.component-total {
        text-align: left;
        width: 130px;
      }
    }
  }
  #tos-container {
    clear: both;
  }
  .checkout-buttons {
    padding: 0 0 30px;
    clear: left;
    .fieldset-wrapper {
      padding: 30px 20px;
      #edit-continue {
        margin: 0;
      }
    }
  }

  .customer_profile_billing {
    padding-bottom: 0;
    .panel-body {
      padding: 0;
    }
    .fieldset-wrapper {
      padding-top: 0;
      .form-item {
        margin: 10px 10px 10px 0;
        width: 100%;
        float: none;
      }
    }
    div > .form-submit {
      margin-bottom: 20px;
    }
    .panel-body > .form-group {
      margin: 0;
    }
  }
  #checkout-toggle-button {
  //@include blue-button;
    float: left;
    height: auto;
    margin-left: 20px;
    text-decoration: none;
  }
  #shopping-cart-contents {
    clear: left;
    fieldset.panel legend {
      border-bottom: 1px solid #e7e7e7;
      line-height: 25px;
      color: #333;
      .panel-title {
        font-size: 21px;
      }
      .panel-body {
        padding: 0;
      }
    }
  }
}
#edit-customer-billing-information {
  > legend {
    line-height: 30px;
  }
}
#payment-container {
  background: #f2f2f2;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  max-width: auto;
  .payment-wrapper {
    padding: 20px;
    input.form-text {
      min-width: inherit;
      max-width: 100%;
      width: 100%;
      font-size: 16px;
      padding-left: 10px;
    }
  }
  .cc-code,
  .form-item-commerce-payment-payment-details-credit-card-code {
    clear: left;
    input.form-text {
      width: 150px;
    }
  }
  .commerce_payment,
  .fieldset-wrapper {
    padding: 0;
  }
  .form-item-commerce-payment-payment-details-cardonfile {
    width: 100%;
  }
  div.error {
    background: transparent;
  }
  div.messages.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    margin: 0 0 20px;
  }
}

#blimp-vouchers-redeem-ajax-wrapper {
  padding-top: 38px;
  position: relative;
  max-width: 500px;
  .blimp_vouchers_redeem {
    margin-bottom: 0;
  }

  > fieldset > legend {
    display: none;
  }
  .panel-body {
    padding: 0;
  }
  .form-item {
    float: left;
    margin: 0 10px 0 0;
    max-width: 50%;
    input.form-text {
      font-size: 16px;
      max-width: 100%;
      min-width: 100px;
    }
  }
  .form-submit {
    padding: 0 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
}
.voucher-subtitle {
  font-size: 15px;
  margin-bottom: 10px;
}
#voucher-form,
.voucher-status,
.voucher-list {
  padding: 20px;
  background: #f2f2f2;
  margin-bottom: 0;
}
#cancel-voucher {
  color: @gray;
  cursor: pointer;
  margin-left: 20px;
  text-transform: uppercase;
}
// Toggle interactions.
#voucher-toggle {
  color: @brand-primary;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  span {
    color: #959595;
    display: none;
    padding-left: 20px;
  }
}
#voucher-form.closed {
  display: none;
}
// Voucher list and statuses.
.voucher-list  {
  padding: 20px;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
  }
}
.voucher-wrapper {
  background: @brand-light;
  color: @brand-primary;
  padding: 5px 10px;
  .code {
    color: #6f6666;
  }
}
.voucher-status .messages {
  border-radius: 0;
  margin: 0;
  &.alert-success {
    background: @brand-light;
    color: @brand-primary;
    strong {
      color: @gray;
    }
  }
}
#edit-cart-contents {
  .panel-body {
    padding: 0;
  }
}
#platform-estimate {
  background: #f2f2f2;
  padding: 20px;
  .price {
    float: none;
  }
  // Override default table styles to ensure proper styling.
  table,
  table tr {
    background: transparent;
  }
  table tr.odd td,
  table tr.even td {
    background: transparent;
    border: 0;
  }
  table {
    th {
      font-size: 11px;
      font-weight: 300;
    }
    tr {
      th.product-header {
        text-transform: uppercase;
        font-weight: 300;
      }
      th.quantity-header,
      th.unit-header {
        text-align: center;
      }
      th.total-header {
        text-align: right;
        padding-right: 30px;
      }
      td {
        color: #434343;
        font-size: 15px;
        font-weight: 300;
        padding-top: 15px;
        padding-bottom: 15px;
        &.product-title {
          width: 50%;
          font-size: 12px;
          font-weight: 700;
        }
        &.unit-price,
        &.quantity {
          text-align: center;
        }
        &.total-price {
          text-align: right;
          padding-right: 30px;
        }
        &.divider {
          color: #a1a1a1;
          font-size: 11px;
          font-weight: 300;
          text-transform: uppercase;
          padding-top: 15px;
          padding-bottom: 0;
          .subheader {
            padding-left: 0;
          }
          .message {
            text-transform: none;
            border-bottom: 1px solid #d6d6d6;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
    &.sticky-header tr {
      background: #f2f2f2;
      border-bottom: 1px solid #ccc;
    }
  }
  .commerce-order-handler-area-order-total {
    padding-top: 10px;
  }
  .commerce-price-formatted-components {
    border-top: 1px solid #d6d6d6;
    width: 50%;
    td {
      color: #888888;
      padding: 15px 30px;
      font-size: 15px;
      text-align: right;
    }
    .component-total {
      width: 40%;
    }
    .component-type-estimation-total {
      background: #fff;
      border: 1px solid #ccc;
      color: #434343;
    }
    span {
      color: #ff0000;
      padding-left: 5px;
    }
  }
  .base-price {
    text-decoration: line-through;
  }
  .actual-price {
    opacity: .6;
    font-size: .8em;
  }
}
.estimate-message-wrapper {
  float: right;
  padding: 20px;
  width: 60%;
  .estimate-message {
    color: #666666;
    text-align: right;
    font-size: 12px;
    padding-left: 20px;
    position: relative;
    span {
      color: #ff0000;
      padding-right: 5px;
    }
  }
}
#cards {
  font-size: 11px;
  color: #666;
  line-height: 32px;
  span {
    font: 0/0 none;
    color: transparent;
    &.mc,
    &.visa,
    &.amex {
      background: transparent url("../@{image-path}/icon-mc.png") no-repeat center center;
      height: 32px;
      width: 52px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
    &.visa {
      background-image: url("../@{image-path}/icon-visa.png");
    }
    &.amex {
      background-image: url("../@{image-path}/icon-amex.png");
    }
    &.message {
      font-family: @font-family-sans-serif;
      font-size: 11px;
      color: #666;
      line-height: 32px;
      height: 32px;
      display: inline-block;
      clear: left;
    }
  }
  &.country-us {
    span {
      &.dc,
      &.jcb,
      &.discover {
        background: transparent url("../@{image-path}/icon-discover.png") no-repeat center center;
        height: 32px;
        width: 52px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      &.jcb {
        background-image: url("../@{image-path}/icon-jcb.png");
      }
      &.dc {
        background-image: url("../@{image-path}/icon-dc.png");
      }
    }
  }
}
.commerce_payment {
  background: transparent;
  .panel-body {
    padding: 15px 0;
  }
  .commerce-payment-method {
    display: none;
  }
}
#payment-details {
  .form-item input {
    height: 40px;
    &.form-radio {
      height: auto;
    }
  }
  .chosen-container-single .chosen-single div {
    width: 20px;
  }
  .form-item label {
    margin-bottom: 5px;
    line-height: 1.5em;
  }
  .form-item label .help {
    color: #707070;
    font-size: 80%;
  }
  .chosen-container-single .chosen-single span {
    margin-right: 0;
  }
  .commerce-credit-card-expiration {
    float: left;
    clear: left;
    width: 100%;
    .commerce-month-year-divider {
      display: none;
    }
    .form-item {
      float: left;
      margin-top: 0;
    }
    .form-item-commerce-payment-payment-details-credit-card-exp-month,
    .form-item-credit-card-exp-month {
      width: 70%;
      .chosen-container {
        width: 50% !important;
      }
    }
    .form-item-commerce-payment-payment-details-credit-card-exp-year,
    .form-item-credit-card-exp-year {
      width: 30%;
      float: right;
    }
    label {
      float: left;
      font-weight: 300;
      color: #acacac;
      line-height: 40px;
      height: 40px;
      width: 45%;
      margin-right: 5%;
      .form-required {
        font: 0/0 none;
      }
    }
    .chosen-container {
      background: #fff;
      min-width: 50px;
      width: 100% !important;
    }
  }
  select.stripe-exp-month,
  select.stripe-exp-year,
  input.stripe-code {
    min-width: 100px;
    width: 100px;
  }
  select {
    height: auto;
    padding: 2px 10px;
    min-width: 0;
    width: auto;
  }
  // Adjustment for webkit browsers.
  @media (-webkit-min-device-pixel-ratio: 0) {
    select {
      height: 40px;
      padding-right: 22px;
    }
  }
}
.checkout-completion-message {
  h2 {
    margin-bottom: 50px;
  }
}
.platform-error,
.page-checkout-review .checkout-help {
  background: transparent url("@{image-path}/icon-bot.png") no-repeat bottom center;
  padding-bottom: 120px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  .message {
    font-size: 14px;
    padding-top: 5px;
  }
}
.page-checkout-review .message {
  font-size: 14px;
  padding-top: 5px;
  font-weight: 200;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.commerce-license-checkout {
  padding-bottom: 40px;
  .platform-building {
    background: url("@{image-path}/platform-bot.gif") no-repeat top center;
    padding-top: 130px;
    text-align: center;
    font-size: 18px;
    font-weight: 200;
  }
  .message {
    font-size: 14px;
    padding-top: 5px;
    font-weight: 200;
    text-align: center;
  }
  .ajax-progress-throbber {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin: 0 0 0 -5px;
    display: none;
  }
}
.commerce_license_complete {
  position: relative;
  .message {
    max-width: 600px;
    margin: 0 auto;
  }
}
#edit-commerce-license-complete {
  .commerce-license-checkout {
    height: 350px;
  }
  .ajax-progress-throbber {
    display: none;
  }
}
body.page-checkout-register {
  h1.page-header {
    margin-bottom: 10px;
  }
  h2 {
    color: #5d5e5e;
    line-height: 1.5em;
  }
  .form-submit {
    padding: 15px 30px;
  }
}
#commerce-checkout-form-login-register {
  .messages.error {
    margin-bottom: 40px;
    .message-content {
      background-position: 0 15px;
      padding: 20px 0 20px 50px;
    }
  }
}

.free-trial-checkout #trial-registration {
  h2.location-info {
    font-size: 12px;
    text-transform: none;
  }
  .platform-area {
    padding-top: 30px;
  }
  .field-type-addressfield {
    width: 300px;
    max-width: 100%;
    margin: 0 auto 30px;
    fieldset.panel {
      border: none;
    }
    .panel-body {
      padding: 0;
    }
  }
}
@media (max-width: 992px) {
}
