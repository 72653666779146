// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:focus,
  &.focus {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 25%);
  }
  &:hover {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: @color;
      background-color: darken(@background, 17%);
      border-color: darken(@border, 25%);
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
      border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}


/*
 * Social Buttons for Bootstrap customized to work without less mixins
 * (since we are using CDN for the bootstrap files)
 * https://github.com/lipis/bootstrap-social
 */

.btn-social {
  position: relative;
  padding: 6px 12px 6px 40px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: @font-family-sans-serif;
  font-weight: 700;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    font: normal normal normal 1.6em/30px FontAwesome;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  &.btn-lg {
    padding-left: 30px;
    :first-child {
      line-height: 30px;
      width: 30px;
      font-size: 1.8em;
    }
  }
  &.btn-sm {
    padding-left: 25px;
    :first-child {
      line-height: 25px;
      width: 25px;
      font-size: 1.4em;
    }
  }
  &.btn-xs {
    padding-left: 20px;
    :first-child {
      line-height: 20px;
      width: 20px;
      font-size: 1.2em;
    }
  }
}

.btn-social-icon {
  .btn-social;
  height: 32px;
  width: 32px;
  padding: 0;
  :before {
    border: none;
    text-align: center;
    width: 100%!important;
  }
  &.btn-lg {
    height: 32px;
    width: 32px;
    padding-left: 0;
    padding-right: 0;
  }
  &.btn-sm {
    height: 27px;
    width: 27px;
    padding-left: 0;
    padding-right: 0;
  }
  &.btn-xs {
    height: 22px;
    width: 22px;
    padding-left: 0;
    padding-right: 0;
  }
}

.btn-social(@color-bg, @color: #fff) {
  background-color: @color-bg;
  .button-variant(@color, @color-bg, rgba(0,0,0,.2));
}


.btn-adn           { .btn-social(#d87a68); }
.btn-bitbucket     { .btn-social(#205081); }
.btn-dropbox       { .btn-social(#1087dd); }
.btn-facebook      { .btn-social(#3b5998); }
.btn-flickr        { .btn-social(#ff0084); }
.btn-foursquare    { .btn-social(#f94877); }
.btn-github        { .btn-social(#444444); }
.btn-google        { .btn-social(#dd4b39); }
.btn-instagram     { .btn-social(#3f729b); }
.btn-linkedin      { .btn-social(#007bb6); }
.btn-microsoft     { .btn-social(#2672ec); }
.btn-openid        { .btn-social(#f7931e); }
.btn-pinterest     { .btn-social(#cb2027); }
.btn-reddit        { .btn-social(#eff7ff, #000); }
.btn-soundcloud    { .btn-social(#ff5500); }
.btn-tumblr        { .btn-social(#2c4762); }
.btn-twitter       { .btn-social(#55acee); }
.btn-vimeo         { .btn-social(#1ab7ea); }
.btn-vk            { .btn-social(#587ea3); }
.btn-yahoo         { .btn-social(#720e9e); }
