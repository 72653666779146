// Bootstrap library.
//@import 'bootstrap.less';

// Base-theme overrides.
//@import 'overrides.less';

// Theme specific.
@import 'header';
@import 'content';
@import 'footer';

body.navbar-is-fixed-top.not-logged-in {
  padding-top: 0 !important;
}
body.platform-styles,
body.not-logged-in.page-platform-login,
body.not-logged-in.page-user {
  font-family: @font-family-sans-serif;
  height: 100%;
  background: #fff;
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: @font-family-sans-serif;
  }
  .page-wrapper {
    min-height: 100%;
    margin-bottom: -91px;
    &:after {
      content: "";
      display: block;
      height: 91px;
    }
  }
  .navbar {
    background: transparent;
  }
  .alert-block {

  }
  h1,h2 {
    text-align: center;
    font-weight: 200;
  }

  h1.page-header {
    border: none;
    font-size: 34px;
    margin: 0;
    color: #000;
    line-height: 1em;
  }
  h2 {
    color: #5d5e5e;
    font-size: 18px;
  }
  h2.subtitle,
  p.title-text {
    width: 600px;
    max-width: 100%;
    margin: 0 auto 50px;
  }
  h2.subtitle {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p.title-text {
    font-size: 12px;
    text-align: center;
    font-weight: 200;
  }
  .header-container {
    padding-top: 50px;
  }
  .main-container {
    padding: 0;
    border-top: none;
  }
  a {
    color: @brand-primary;
    &:hover {
      color: @link-hover-color;
    }
  }

  // Button style overrides.
  .btn-primary {
    background-color: @brand-primary;
    border-color: @brand-primary;
    color: #fff;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @link-hover-color;
      border-color: @link-hover-color;
    }
  }

  .form-control,
  .btn,
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-single {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .form-control {
    border: 1px solid #e5e5e5;
    height: 40px;
    &.error {
      border-color: @brand-danger;
    }
  }
  .btn {
    border-radius: none;
  }
  .form-submit {
    background: @brand-primary;
    border-color: @brand-primary;
    color: #fff;
    padding: 12px 50px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    &:hover,
    &:focus {
      background: @link-hover-color;
      border-color: @link-hover-color;
      color: #fff;
    }
    &.progress-disabled,
    &.progress-disabled:hover,
    &[disabled],
    &[disabled]:hover {
      background: @link-hover-color;
      border-color: @link-hover-color;
      color: #fff;
      opacity: .5;
    }
    &.btn-social {
      padding: 0px 30px 0px 60px;
      display: block;
      margin: 0 auto 10px;
      line-height: 40px;
      text-transform: none;
      font-size: 14px;
      box-shadow: 0px 1px 4px rgba(26, 25, 43, 0.24);
      border-radius: 2px;
      color: #4A495E;
      &:before {
        border-right: none;
        font-size: 25px;
        line-height: 43px;
        left: 5px;
        content: ""
      }
      &:hover, &:focus {
        color: #145CC6;
        border: none;
      }
    }
    &.btn-bitbucket {
      background-color: #fff;
      &:before {
        background: url(../images/bitbucket.svg) center no-repeat;
      }
    }
    &.btn-github {
      background-color: #fff;
      &:before {
        background: url(../images/github.svg) center no-repeat;
      }
    }
    &.btn-google {
      background-color: #fff;
      &:before {
        background: url(../images/google.svg) center no-repeat;
      }
    }
  }
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-single {
    line-height: 1.428571429;
    height: 40px;
    padding: 6px 12px;
    background: #fff;
    border: 1px solid #e5e5e5;
    span {
      line-height: 26px;
    }
    div {
      width: 25px;
      b {
        width: 25px;
      }
    }
  }
  .chosen-container.chosen-with-drop .chosen-drop {
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .error {
    .form-control,
    .chosen-container-single .chosen-single,
    .chosen-container-active.chosen-with-drop .chosen-single {
      border-color: #f57f84;
      color: #f57f84;
    }
  }
  input[type="file"], input[type="text"], input[type="password"], select, select.form-control, fieldset, fieldset.panel, .resizable-textarea textarea {
    color: #000;
  }
}

html {
  height: 100%;
  background: #fff;
}

// Simple fixed footer on specific pages.
body.platform-styles,
body.not-logged-in.page-user {
  footer.footer {
    background: #efefef;
    border: none;
    font-weight: 300;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 91px;
    font-size: 14px;
    padding: 0;
    a {
      color: @brand-primary;
    }
    .container {
      padding-top: 35px;
      padding-bottom: 36px;
      border-top: none;
    }
  }
}
body.fixed-footer footer.footer {
  position: fixed;
}
.page-error-not-found label,
.not-logged-in.page-error-not-found  #block-universal-navbar-universal-navbar-account,
.not-logged-in.page-error-denied  #block-universal-navbar-universal-navbar-account,
.not-logged-in.page-user #block-universal-navbar-universal-navbar-account,
.not-logged-in.page-user #block-universal-navbar-universal-navbar-menu {
  display: none;
}
.page-error-not-found  #block-universal-navbar-universal-navbar-account,
.page-error-not-found  #block-universal-navbar-universal-navbar-menu,
.not-logged-in.page-error-denied #block-universal-navbar-universal-navbar-account,
.not-logged-in.page-error-denied  #block-universal-navbar-universal-navbar-menu {
  a {
    color: #000;
    border: none;
    &:hover {
      color: #fff;
    }
  }
  .dropdown a {
    color: #fff;
  }
}
.page-error-not-found  #block-universal-navbar-universal-navbar-menu .universal-navbar-menu,
.not-logged-in.page-error-denied  #block-universal-navbar-universal-navbar-menu {
  margin-left: 30px;
  display: inline-block;
}
.page-error-not-found,
.not-logged-in.page-error-denied {
  h1.page-header {
    text-align: center;
  }
  .region-content {
    padding-top: 0;
  }
}
.not-logged-in.page-user .navbar-toggle,
.page-platform-login .navbar-toggle {
  display: none;
}
// Node 7 is the page not found node.
.page-error-not-found .field-name-field-subtitle,
.page-error-denied .field-name-field-subtitle {
  text-align: center;
  color: #5d5e5e;
  font-size: 18px;
  font-family: @font-family-sans-serif;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 30px;
}
.page-node.page-error-not-found .region-content {
  padding-top: 0;
}
body.not-logged-in.page-user {
  .main-container {
    padding-top: 20px;
  }
}
body.not-logged-in.page-platform-login {
  h1.page-header {
    margin-top: 40px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 36px;
  }
}
@media (max-width: 960px) {
  ul.feature-list {
    padding: 0 0 0 30px;
  }
}

// Page-specific styles
@import 'estimate';
@import 'checkout';
@import 'forms';
@import '../bootstrap-social';

// Responsive fixes should load last.
@import 'responsive';
