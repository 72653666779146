// Insert your styling here.
.navigation-wrapper {
  background: #1a192b;
  border: none;
  padding: 15px 0;
  z-index: 100;
}
body.navbar-is-fixed-top {
  padding-top: 0!important;
}
body.navbar-is-fixed-top .page-wrapper {
  padding-top: 0;
}
// Fix margin when admin-menu is enabled.
body.admin-menu .navbar-fixed-top {
  top: 28px;
}
body.admin-menu.navbar-is-fixed-top {
  padding-top: 0!important;
}
// Fix margin when user menu is displayed.
body.logged-in.navbar-is-fixed-top .page-wrapper {
  padding-top: 159px;
}
body.platform-styles.navbar-is-fixed-top .page-wrapper {
  padding-top: 0;
}
body.admin-menu.platform-styles.navbar-is-fixed-top .page-wrapper {
  margin-top: 29px !important;
}
body.admin-menu.platform-styles.navbar-is-fixed-top.node-type-webform,
body.admin-menu.platform-styles.navbar-is-fixed-top.node-type-basic-page {
  padding-top: 0 !important;
}
#navbar .navbar-header {
  margin: 0;
}
#navbar .logo {
  width: 138px;
  padding-left: 0;
  padding-right: 0;
  img {
    max-width: 100%;
  }
}
.userbar-wrapper {
  background-color: #000;
  color: #8b8989;
  position: relative;
}
.container>.navbar-header {
  margin-left: 0;
  margin-right: 0;
}
