// Insert your styling here.
.page-platform-login #user-login,
.not-logged-in.page-user #user-login,
.not-logged-in.page-error-denied #user-login,
.not-logged-in.page-error-not-found #user-login,
.not-logged-in.page-user #user-register-form {
  label {
    display: none;
  }
  .form-type-radios {
    text-align: left;
  }
  .form-type-radio {
    margin-bottom: 15px;
    label {
      display: inline-block;
      text-align: left;
    }
  }
  .login-form-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 50px;
  }
  .login-password {
    display: block;
    font-size: 11px;
    font-weight: 300;
    font-style: italic;
    text-align: left;
    color: #858585;
    padding-left: 5px;
    margin-bottom: 30px;
  }
  .form-submit {
    margin: 0 auto 30px;
    display: block;
  }
  .privacy {
    margin-bottom: 30px;
  }
}
#block-openid-connect-openid-connect-login {
  clear: both;
  text-align: center;
}
#blimp-platform-user-pass {
  max-width: 300px;
  margin: 0 auto;
  padding-top: 50px;
  .form-submit {
    margin: 0 auto;
    padding: 12px 20px;
    display: block;
    width: 100%;
  }
  a {
    display: block;
    font-size: 11px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    color: #858585;
  }
}

// No platform page styles.
.get-platform {
  text-align: center;
  padding: 30px 0;
  .btn-primary {
    font-size: 14px;
    text-transform: uppercase;
  }
}
// Platform proojects page.
.user-platform-list {
  max-width: 680px;
  margin: 0 auto;
  padding-top: 50px;
  .region {
    float: left;
    width: 45%;
    margin: 0 2.5% 30px;
    ul {
      padding: 0;
    }
    li {
      list-style: none;
      a {
        display: inline-block;
        width: 100%;
        padding: 20px;
        color: #000;
        &:hover {
          color: @brand-primary;
          text-decoration: none;
          background-color: @brand-light;
        }
      }
    }
  }
  .region:nth-child(2n+1) {
    clear: left;
  }
  h3 {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 20px;
  }
}
.messages {
  padding: 15px;
  margin: 0 15px 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
}
// Invite registration form.
.user-info-from-cookie {
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
  max-width: 300px;
  .help-block {
    text-align: left;
    margin-left: 0;
    padding-left: 1em;
  }
  .form-control-feedback {
    top: 3px;
  }
  .form-type-password-confirm .form-type-password {
    width: 100%;
    input {
      width: 100%;
    }
  }
  .form-type-password-confirm .help-block {
    display: none !important;
  }
}

// These headers are needed in other themes, but duplicated on this one.
.view-id-node_header h2 {
  font: 0/0 none;
}

// Platform.sh navigation links.
.navbar-default .navbar-nav {
  float: none;
  >li>a {
    color: #cff1ff;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 10px 15px;
    &:hover {
      color: #fff;
    }
  }
  >:nth-child(6) a {
    border: 1px solid #fff;
  }
}

footer.footer {
  background: #272828;
  color: #a6a6a6;
  font-size: 12px;
  font-weight: 300;
  height: auto;
  padding: 75px 0 0;
  a {
    color: #A6A6A6;
    &:hover {
      color: #fff;
    }
  }
  h6 {
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      line-height: 2em;
    }
  }
  .social-links {
    margin-bottom: 30px;
    ul li {
      margin-right: 10px;
      display: inline;
    }
  }
  .container {
    border-top: 2px solid #434341;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .closing-container {
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 11px;
    .built-by,
    .copyright,
    .privacy {
      display: inline-block;
      padding: 0 10px;
    }
    a {
      color: #fff;
    }
    .built-by a {
      color: #00b4ff;
    }
  }
}
