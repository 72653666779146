// Platform Pricing page styles.
#blimp-platform-pricing-form {
  padding: 10px;
  fieldset {
    width: 100%;
    border-radius: 0;
    text-align: left;
    margin-bottom: 0;
    box-shadow: none;
  }
  // Override a bootstrap setting.
  .panel-default>.panel-heading {
    background: transparent;
    border: none;
    position: absolute;
  }
  .panel-heading {
    text-transform: uppercase;
    color: @brand-primary;
    padding: 20px 30px;
  }
  fieldset .fieldset-legend {
    font-size: 14px;
  }
  #edit-platform-base {
    position: relative;
  }
  #edit-platform-base > .fieldset-wrapper {
    padding: 0;
  }

  .tax-info {
    color: #434343;
    font-size: 12px;
    text-align: left;
    padding: 10px 0 0 45px;
    position: relative;
    margin-bottom: 50px;
    span {
      color: @brand-primary;
      font-size: 18px;
      vertical-align: sub;
    }
  }
}
#edit-platform-base .panel-body {
  margin-top: 95px;
  padding: 0;
  .production-wrapper {
    padding: 0;
    position: relative;
  }
  .production {
    position: absolute;
    top: 65px;
    width: 100%;
    float: right;
    border-top: 1px solid #eeeeee;
    text-align: center;
    box-sizing: border-box;
    span {
      background: #fff;
      text-transform: uppercase;
      color: #959595;
      position: relative;
      top: -11px;
      font-size: 12px;
      display: inline-block;
      padding: 0 10px;
    }
    &.active {
      border-top-color: @brand-primary;
      span {
        color: @brand-primary;
      }
    }
  }
}
#edit-platform-base-platform-base-option {
  background: transparent;
  margin: 0 auto;
  padding: 0 28px;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;

  .form-item {
    padding: 0;
    width: 100%;
    min-width: 85px;
    text-align: center;
    float: left;
    margin: 0;

    &:first-child {
      margin-bottom: 40px;
    }
  }

  label {
    border: 1px solid lighten(@brand-primary, 37%);
    margin: 0;
    cursor: pointer;
    padding: 0 5px;
    height: 47px;
    line-height: 47px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
    &:hover {
      //color: $turquoise;
    }
  }

  input {
    position: absolute;
    left: -9999px;
  }
  .active label {
    background: @brand-primary;
    color: #fff;
    border: 1px solid @brand-primary;
  }
}

#edit-platform-estimate .fieldset-wrapper {
  background: transparent;
  position: relative;
}
#edit-platform-estimate-submit {
  background: @brand-primary;
  border: none;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  padding: 10px 40px;
  text-transform: uppercase;
  margin: 0 auto;
  &:hover {
    background: @link-hover-color;
  }
}
#features {
  padding: 5px 30px;
  .feature-list {
    margin-bottom: 30px;
    label {
      color: @gray-dark;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
  .environment {
    display: none;
    &:first-child  {
      display: inline-block;
      width: 100%;
    }
  }
  ul {
    float: none;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    li {
      margin-left: 45%;
      font-size: 12px;
      padding-left: 35px;
      max-width: 55%;
      &.base-feature-1,
      &.base-feature-2,
      &.base-feature-3 {
        margin-left: 0;
        float: left;
        clear: left;
      }
    }
  }
  .live-only {
    width: 100%;
  }
  .price {
    text-align: center;
    margin-bottom: 20px;
  }
  h4 {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .cost {
    font-weight: 300;
    font-size: 36px;
    line-height: 1em;
  }
  .per-month {
    font-size: 12px;
    text-transform: uppercase;
    color: @gray-dark;
  }

}
div.all {
  dislay: inline-block;
  width: 100%;
  padding: 15px;
  margin: 0;
  background: #b2e7fa;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    color: @gray-dark;
    list-style: none;
    font-size: 13px;
    font-weight: 200;
    padding-left: 25px;
    background: transparent url("@{image-path}/icon-check2.png") no-repeat 0 3px;
  }
}
#edit-platform-customization {
  position: relative;
  .panel-body {
    padding: 15px 30px;
    margin-top: 95px;
  }
  .fieldset-wrapper {
    position: relative;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .custom-field-wrapper {
    label {
      color: @gray-dark;
      left: 70px;
      top: 3px;
      font-weight: 300;
      font-size: 12px;
    }
    .description {
      padding-top: 25px;
      padding-right: 50px;
      margin-left: 60px;
      line-height: 1em;

    }
    select,
    .chosen-container {
      min-width: 50px;
      float: left;
    }
    .form-control {
      border: none;
      border-radius: 0;
      background: #e3e3e3;
    }
    .chosen-container-single {
      .chosen-single {
        background: #e3e3e3;
        border-radius: 0;
        height: 33px;
        min-width: auto;
        span {
          margin-right: 25px;
          color: #4b4c4c;
          height: 25px;
          line-height: 25px;
          width: 100%;
        }
        div {
          width: 25px;
        }
        div b {
          background-size: auto !important;
          width: 25px;
        }
      }
      .chosen-drop {
        -webkit-border-radius: 0;
        border-radius: 0;
        border: 0;
        border-top: 1px solid #777777;
        background: #e3e3e3;
        min-width: auto;
        .chosen-results {
          padding: 0;
          margin-right: 0;
          .highlighted {
            //background: $turquoise;
          }
        }
      }
    }
    .chosen-container-active.chosen-with-drop {
      .chosen-single {
        background: #e3e3e3;
        div b {
          background-position: left center;
          background-size: auto !important;
        }
      }
    }
  }
  .chosen-container-single .chosen-single,
  .chosen-container-active.chosen-single-with-drop {
    background: transparent;
    border: 0;
    box-shadow: none;
  }
  .total {
    padding-right: 10px;
    span {
      font-size: 18px;
      vertical-align: top;
      color: @brand-primary;
      padding-top: 5px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .form-required {
    display: none;
  }
  .price-info {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    padding: 0 10px 0 30px;
    position: absolute;
    bottom: 15px;
    left: 0;
    span {
      color: @brand-primary;
      position: absolute;
      left: 10px;
      font-size: 18px;
      font-weight: 200;
    }
  }
  .help-block {
    padding: 20px 0 0 70px;
    color: #b1b1b1;
    font-size: 11px;
  }
  .form-item-platform-customization-PLATFORM-OPTION-STORAGE .help-block {
    width: 170px;
  }
}
#temp-field {
  float: left;
  background-color: #e3e3e3;
  color: #4b4c4c;
  line-height: 25px;
  min-width: 50px;
  height: 33px;
  padding: 6px 12px;
  margin-top: 5px;
  cursor: not-allowed;
}
#edit-platform-estimate {
  border: none;
  .panel-body {
    padding: 0;
  }
}
#final-total {
  color: @gray-dark;
  margin-bottom: 0;
  background: @brand-light;
  border: 1px solid #ddd;

  .total-title {
    float: left;
    margin-right: 30px;
    font-weight: 300;
    font-size: 18px;
    line-height: 40px;
  }

  .total-wrapper {
    color: #363636;
    position: relative;
    margin: 0;
    display: inline-block;
    .ajax-progress {
      position: absolute;
      left: 100%;
      top: 10px;
      .throbber {
        display: none;
        position: absolute;
        right: -20px;
        height: 14px;
        font-size: 14px;
        background: transparent;
        padding: 0;
        margin: 0 -.25em 0 0.5em;
        opacity: .5;
        top: 2px;
        width: 14px;
      }
    }
  }

  #number {
    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
  }
  .extra {
    color: @brand-primary;
    font-size: 18px;
    line-height: 1em;
    position: absolute;
    top: 10px;
    right: 115%;
  }
}
#edit-platform-currency-switcher {
  text-align: center;
  padding-top: 30px;
  margin-bottom: 30px;
  .radio {
    display: inline-block;
    padding: 0;
    margin: 0;
    position: relative;
    input {
      position: absolute;
      left: -9999px;
    }
  }
  label {
    border: 1px solid lighten(@brand-primary, 22%);
    display: inline-block;
    padding: 5px 10px;
    font-size: 12px;
    color: #959595;
  }
  .radio.active label,
  .radio:hover label {
    background: @brand-light;
    border: 1px solid lighten(@brand-primary, 22%);
    color: @gray-dark;
    text-decoration: none;
  }
}
// Override platform module style settings.
#blimp-platform-pricing-form #features ul {
  float: none;
  width: 100%;
  li {
    padding-left: 30px;
    font-weight: 300;
  }
}
#blimp-platform-pricing-form #features ul.active li {
  background: @brand-light;
  position: relative;
  padding: 5px 5px 5px 45px;
  &:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    position: absolute;
    top: 5px;
    left: 20px;
    font-size: 15px;
    color: @brand-primary;
  }
  &.base-feature-1,
  &.base-feature-2,
  &.base-feature-3 {
    background: transparent;
    &:before {
      color: #8e8e8e;
      left: 5px;
    }
  }
  &.base-feature-1,
  &.base-feature-2,
  &.base-feature-3 {
    padding-left: 30px;
    width: 45%;
  }
  &.base-feature-1,
  &.base-feature-4 {
    padding-top: 20px;
    background-position: 20px 21px;
    &:before {
      top: 20px;
    }
  }
  &.base-feature-1 {
    background-position: 0 21px;
  }
  &:last-child {
    padding-bottom: 20px;
  }
}
#blimp-platform-pricing-form #features h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 35px;
}
#blimp-platform-pricing-form .custom-field-wrapper .total {
  font-size: 30px;
  font-weight: 300;
  right: -15px;
}
#blimp-platform-pricing-form #final-total {
  padding: 30px;
}
#blimp-platform-pricing-form #features #development {
  ul.active li.base-feature-4 {
    background: transparent;
    span {
      color: #747576;
      font-size: 12px;
    }
    &:before {
      color: #8e8e8e;
    }
  }
}
