#commerce-checkout-form-login-register,
#user-register-form,
#login-form,
.page-platform #user-login {

  h2 {
    margin-bottom: 50px;
  }
  .privacy {
    padding-top: 10px;
    font-size: 12px;
    color: #6c6c6c;
    margin-bottom: 30px;
  }
  #register-toggle,
  #user-login {
    .form-fields {
      width: 300px;
      margin: 0 auto;
    }
  }
  .form-fields,
  .form-group {
    position: relative;
  }
  .form-submit {
    display: block;
    margin: 0 auto 30px;
    clear: both;
    text-align: center;
  }
  .login-password {
    display: block;
    font-size: 11px;
    font-weight: 300;
    font-style: italic;
    text-align: left;
    color: #858585;
    padding-left: 5px;
    margin-bottom: 20px;
  }
  label {
    display: none;
  }
  .form-type-radio label {
    display: inline-block;
  }
  .captcha {
    margin-bottom: 15px;
  }
  .form-item-mail .help-block,
  .password-suggestions {
    // Without important, password suggestions appear.
    display: none !important;
  }
  .form-type-password,
  input.password-confirm,
  input.password-field {
    width: 100%;
  }
  .form-type-password {
    position: relative;
    margin-bottom: 15px;
  }
  .form-item-pass-pass1 {
    padding-bottom: 30px;
  }
  .password-indicator {
    background: #efecec;
    div {
      background: #1fba38;
    }
  }
  .password-strength {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 0;
  }
  .password-strength {
    font-size: 11px;
    padding-bottom: 10px;
    color: #363636;
    .password-strength-text {
      text-transform: uppercase;
    }
  }
  div.password-confirm {
    font: 0/0 none;
    color: transparent;
    padding-left: 0;
  }
  .form-type-password-confirm {
    .form-control-feedback {
      left: 100%;
      top: 0;
    }
    &.error-processed:before {
      content: none !important;
    }
    .has-error .form-control-feedback {
      background: transparent url("@{image-path}/icon-error.png") no-repeat center center;
      height: 20px;
      width: 20px;
      top: 9px;
      margin-left: 20px;
      &:before {
        content: '';
      }
    }
    .has-success .form-control-feedback {
      background: transparent url("@{image-path}/icon-check3.png") no-repeat center center;
      height: 20px;
      width: 20px;
      top: 9px;
      margin-left: 20px;
      &:before {
        content: '';
      }
    }
    .has-warning .form-control-feedback {
      right: -33px;
    }
    .help-block {
      display: none !important;
    }
  }
  .form-item.has-error,
  .form-item.error {
    position: relative;
    background: transparent;
    input {
      border-color: #f57f84;
      color: #f57f84;
    }
    &:before {
      content: " ";
      background: transparent url("@{image-path}/icon-error.png") no-repeat center center;
      height: 20px;
      width: 20px;
      position: absolute;
      left: 100%;
      top: 9px;
      margin-left: 20px;
    }
  }

  .form-type-password-confirm:before,
  .form-item-pass:before,
  .form-type-password:before,
  .form-type-radio:before {
    display: none !important;
  }
  .form-item.error {
    padding: 0;
  }
  .form-fields .ok,
  .form-item.ok  {
    position: relative;
    &:before {
      content: "";
      background: transparent url("@{image-path}/icon-check3.png") no-repeat center center;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
      margin-left: 20px;
    }
  }
  .form-item-pass-pass1.ok,
  .form-item-pass-pass2.ok {
    &:before {
      display: none;
    }
  }
  .require-message {
    color: @brand-danger;
    font-size: 12px;
    text-transform: uppercase;
    width: 160px;
  }
  .chosen-container {
    text-align: left;
  }
}
#user-register-form {
  padding-top: 0;
  a.chosen-single {
    color: #666;
  }
  .chosen-container-active a.chosen-single {
    color: #000
  }
}
.form-item-pass .row {
  margin: 0;
  .form-item {
    padding: 0;
  }
}
.page-user-password {
  .breadcrumb,
  label {
    display: none;
  }
  h2 {
    font-size: 36px;
    font-weight: 200;
    margin-bottom: 30px;
  }
  #user-pass {
    width: 300px;
    margin: 0 auto;
  }
  .form-item {
    position: relative;
  }
  .require-message {
    position: absolute;
    left: 100%;
    top: 0;
    line-height: 40px;
    padding-left: 10px;
  }
}
body.node-type-webform,
body.node-type-basic-page {
  select {
    padding: 0 30px 0 10px;
  }
  input[type="text"],
  textarea {
    border: 1px solid #e2e0e0;
  }
  .nav-tabs{
    margin-bottom: 30px;
  }
  .pane-node-webform {
    width: 600px;
    max-width: 100%;
    margin: 0 auto 50px;
    .form-item {
      margin-bottom: 30px;
      &.radio {
        margin-bottom: 10px;
      }
    }
    fieldset.panel {
      border: none;
    }
    .panel-default>.panel-heading {
      background: transparent;
      border-bottom: none;
    }
    .control-label {
      margin-bottom: 10px;
    }
    .description {
      font-size: 12px;
      padding-top: 10px;
    }
  }
}
