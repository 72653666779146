// Anything higher than mobile.
@media (min-width: 768px) {
  .navbar-default .navbar-nav {
    float: none;
    clear: left;
    width: 100%;
    >li>a {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 11px;
    }
  }
  #edit-platform-base-platform-base-option .form-item:first-child {
    margin-bottom: 0;
  }
  #edit-platform-base-platform-base-option {
    display: flex;
    justify-content: space-between;
  }
  #edit-platform-base-platform-base-option .form-item {
    width: 100%;
    padding: 0 2px;
  }
  #edit-platform-base .panel-body,
  #edit-platform-customization .panel-body {
    margin-top: 65px;
  }
  #edit-platform-base .panel-body .production-wrapper {
    padding: 0 28px;
    .production {
      position: relative;
      float: right;
      width: 81%;
      top: auto;
    }
    &.options-4 .production {
      width: 75%;
    }
    &.options-5 .production {
      width: 80%;
    }
    &.options-6 .production {
      width: 83%;
    }
    &.options-7 {
      padding-left: 130px;
      .production {
        width: 100%;
      }
    }
  }
  .options-6 #edit-platform-base-platform-base-option .form-item {
    label {
      font-size: 11px;
    }
  }
  .options-7 #edit-platform-base-platform-base-option .form-item {
    width: auto;
  }
  #commerce-checkout-form-register {
    .platform-area {
      padding-top: 60px;
    }
    .blimp-platform-non-selectable-platforms {
      display: block;
      padding-top: 30px;
      .entity-blimp-platform {
        float: left;
        text-align: center;
      }

    }
  }
  #cards span.message {
    clear: none;
  }
  #payment-details .commerce-credit-card-expiration {
    width: 40%;
  }
  #payment-details .cc-owner,
  #payment-details .form-item-commerce-payment-payment-details-credit-card-owner {
    float: left;
    width: 40%;
  }
  #payment-details .cc-accept,
  #payment-details .form-item-commerce-payment-payment-details-credit-card-number {
    float: right;
    width: 57%;
  }
  #payment-details .cc-code,
  #payment-details .form-item-commerce-payment-payment-details-credit-card-code {
    clear: right;
    float: right;
    width: 50%;
  }
  #payment-details .commerce-credit-card-expiration {
    width: 46%;
  }
  #features .feature-list {
    margin-left: 30%;
  }
  #features .price {
    max-width: 33%;
    float: left;
  }
  div.all {
    li {
      width: 36%;
      margin-right: 3%;
      vertical-align: top;
      display: inline-block;
      &:first-child,
      &:last-child {
        width: 28%;
      }
      &:first-child {
        margin-left: 2%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  #commerce-checkout-form-login-register,
  #user-register-form,
  #login-form,
  .page-platform #user-login {
    .require-message {
      position: absolute;
      right: auto;
      left: 100%;
      top: 11px;
      margin-left: 50px;
      text-align: left;
    }
    .form-item-pass-pass1 {
      padding-bottom: 0;
    }
    .password-strength {
      bottom: auto;
      top: 9px;
      left: 100%;
      margin-left: 20px;
    }
    .form-fields .ok:before,
    .form-fields .error:before,
    .form-item.ok:before,
    .form-item.error:before {
      right: auto;
      left: 100%;
    }
  }
  .page-platform .container>.navbar-collapse,
  .page-checkout .container>.navbar-collapse {
    margin-left: 255px;
  }
  #block-commerce-checkout-progress-indication ul {
    li {
      display: inline-block;
      text-align: center;
      width: 85px;
      padding-left: 0;
      margin: 0 10px;
      height: 35px;
      vertical-align: top;
      &:before {
        top: -30px;
        left: 50%;
        margin-left: -10px;
      }
    }
    &:before {
      position: absolute;
      background: darken(@brand-primary, 3%);
      height: 3px;
      width: 340px;
      top: 10px;
      left: 45px;
    }
  }
  .user-info-from-cookie {
    width: 50%;
  }
  .in-bitbucket-context {
    .navbar-fixed-top {
      position: relative;
    }
    .navigation-wrapper {
      padding: 15px 0;
    }
  }
  body.navbar-is-fixed-top .page-wrapper {
    padding-top: 114px;
  }
  body.platform-styles .form-submit.btn-social,
  body.not-logged-in.page-platform-login .form-submit.btn-social,
  body.not-logged-in.page-user .form-submit.btn-social {
    display: inline-block;
  }
  body.platform-styles .header-container,
  body.not-logged-in.page-user .header-container {
    padding-top: 70px;
  }

  body.platform-styles.navbar-is-fixed-top .page-wrapper {
    padding-top: 94px;
  }
  body.platform-styles.in-bitbucket-context.navbar-is-fixed-top .page-wrapper {
    padding-top: 0;
  }
  .free-trial-checkout {
    #block-commerce-checkout-progress-indication ul {
      &:before {
        width: 270px;
        left: 55px;
      }
      li {
        width: 105px;
      }
    }
  }
}
// Anything higher than tablet
@media (min-width: 992px) {
  .navbar-default .navbar-nav {
    clear: none;
    width: auto;
    >li>a {
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  #blimp-platform-pricing-form #edit-platform-base {
    width: 66%;
    border-right: 0;
    border-bottom: 0;
  }
  .options-7 #edit-platform-base-platform-base-option .form-item,
  .options-8 #edit-platform-base-platform-base-option .form-item {
    min-width: 75px;
  }
  #blimp-platform-pricing-form #edit-platform-customization {
    width: 34%;
    border-bottom: 0;
  }
  .price {
    float: left;
  }
  #commerce-checkout-form-register {
    .platform-area {
      margin: 0 auto;
      padding: 30px 0;
      width: auto;
      .form-type-radio {
        text-align: center;
      }
    }
  }
  #commerce-checkout-form-register {
    .platform-area {
      padding-top: 60px;
    }
    .blimp-platform-non-selectable-platforms {
      display: block;
      padding-top: 30px;
      .entity-blimp-platform {
        float: left;
        text-align: center;
      }
    }
  }
  #block-commerce-checkout-progress-indication ul {
    li {
      width: 140px;
      height: auto;
    }
    &:before {
      width: 495px;
      top: 10px;
      left: 75px;
    }
  }
  #payment-details .commerce-credit-card-expiration {
    width: 50%;
    label {
      width: 55%;
    }
    .form-item-commerce-payment-payment-details-credit-card-exp-month .chosen-container,
    .form-item-credit-card-exp-month .chosen-container {
      width: 40% !important;
    }
  }
  #payment-details .cc-code {
    margin-left: 0;
    margin-top: 0;
    width: 45%;
    float: right;
  }
  #cards span.message {
    clear: left;
    display: inline-block;
  }
  #commerce-stripe-cardonfile-create-form {
    background: #f2f2f2;
    float: left;
    min-width: 50%;
  }
  #commerce-checkout-form-checkout .cart_contents #platform-estimate {
    table tr td.total-price {
      width: 10%;
    }
    .commerce-price-formatted-components .component-total {
      width: 30%;
    }
  }
  .page-checkout #customer-profile-billing-ajax-wrapper,
  .page-checkout #customer-profile-eu-vat-rc-ajax-wrapper {
    clear: left;
    width: 45%;
    float: left;
  }
  .page-checkout #blimp-vouchers-redeem-ajax-wrapper,
  #commerce-checkout-form-checkout #payment-container {
    margin-left: 50%;
  }
  #payment-details .commerce-credit-card-expiration label {
    width: 55%;
  }
  #blimp-platform-pricing-form .custom-field-wrapper .total {
    font-size: 20px;
    line-height: 45px;
    span {
      line-height: 1.5em;
    }
  }
  #blimp-platform-pricing-form #edit-platform-base,
  #edit-platform-customization {
    height: 400px;
  }
  div.all {
    position: absolute;
    bottom: 0;
  }
  .free-trial-checkout {
    #block-commerce-checkout-progress-indication ul {
      &:before {
        width: 335px;
        left: 75px;
      }
      li {
        width: 140px;
      }
    }
  }
  #cancel-voucher {
    margin-left: 10px;
  }
}
// Widescreen
@media (min-width: 1200px) {
  .page-platform .container,
  .page-checkout .container {
    width: 970px;
  }
  .page-checkout-checkout .container {
    width: 1170px;
  }
  #cancel-voucher {
    margin-left: 20px;
  }
}

// Retina screen fixes.
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi)
{
  // Silly chosen and your important code.
  .chosen-rtl .chosen-search input,
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    width: 25px;
  }
}
